.gutter-row{
    margin:0;
}

.participant-name{
    margin-bottom: 0;
}

.ant-card-body{
    padding-bottom: 8px;
}

.ant-card-head{
    padding-right: 4px;
}