@import "../resources/style/variables.scss";

.header.header{
    
    padding: 0;
    margin-bottom: 2px;

    .logo{
        width: 77px;
        height: 31px;
        background: #fff;
        margin: 16px 24px 16px 24px;
        float: left;

    }

    .usertab{
        float:right;
        margin-right: 8px;
    }
}

